<template>
  <div class="news-detail container">
    <!-- 文章 -->
    <article>
      <div class="article-title">{{articleHtml.title}}</div>
      <div class="article-time">{{articleHtml.modifyTime|filterTime}}</div>
      <div class="article-content" v-html="articleHtml.content"></div>
    </article>

    <!-- 附件列表 -->
    <div class="file-list">
      <h3>附件列表</h3>
      <a
        v-for="(v,i) in fileList"
        :key="i"
        :href="baseURL+v"
        target="_blank"
      >{{ v.substring( v.lastIndexOf('/')+1 ) }}</a>
    </div>
  </div>
</template>

<script>
import recentNewsApi from "@/api/recentNews";
import { mapState } from "vuex";

export default {
  async created() {
    // 获取最新动态id
    this.recentNewsId = this.$route.query.recentNewsId;
    let { code, data, message } = await recentNewsApi.getRecentNewsDetail({
      id: this.recentNewsId
    });
    this.articleHtml = data;
    this.fileList = JSON.parse(data.attachFilePath);
  },
  data() {
    return {
      recentNewsId: 0,
      articleHtml: {},
      fileList: []
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>

<style lang="less" scoped>
.news-detail {
  // 文章
  article {
    padding: 30px;
    .article-title {
      text-align: center;
      font-size: 25px;
    }
    .article-time {
      text-align: center;
      color: #979797;
      padding: 20px 0;
      border-bottom: 1px dashed #dcdcdc;
    }
    .article-content {
      padding-top: 10px;
    }
  }

  // 附件列表
  .file-list {
    padding-left: 30px;
    margin-bottom: 30px;
    h3 {
      font-size: 18px;
    }
    a {
      color: #3660d9;
      display: block;
      line-height: 20px;
    }
  }
}
</style>